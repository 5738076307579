.blueDiv {
  width: 100%;
  background-color: var(--black);
  height: 390px;
  color: white;
}

footer {
  display: flex;
  justify-content: space-evenly;
}

.footerItems {
  text-align: justify; 
}

footer p {
  margin-top: 80px;
  font-size: 18px;
  margin-bottom: 10px;
}

footer ul {
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}

footer ul li:hover {
  text-decoration: underline;
  text-decoration-color: blue;
}

.icon {
    margin-right: 15px;
    cursor: pointer;
}

footer input {
  background: #ffffff;
  border: 1px solid #b9b3b8;
  box-sizing: border-box;
  border-radius: 10px;
  height: 38px;
  width: 246px;
  color: #b9b3b8;
  font-size: 16px;
  line-height: 21px;
  text-align-last: center;
  cursor: pointer;
}

/* .mellowDiv {
  width: 100%;
  background-color: var(--mellow);
  height: 31.2px;
} */
