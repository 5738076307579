@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
   text-align: center;
   display: flex;
   flex-direction: column;
   min-height: 100vh;

}

* {
   box-sizing: border-box;
   font-family: 'Jost', "Futura", sans-serif;
   /* COLORS */
   --red: #FF3038;
   --gray: #b9b3b8;
   --alt-gray: #eeeeee;
   --black: #39363c;
   --blue: #016592;
   --mellow: #ffc74e;
   --orange: #feac29;
   /* SIZES */
   --max-content: 1440px;

   /* COLORS v2 */
   --primary: #E35056;
   --primary-light: #E350562B;
   --dark-grey: #B9B3B8;
   --light-grey: #EEEEEE;
   --font-primary: #39363C;
   --font-secondary: var(--dark-grey);
   --link: #788AAE;
   --white: white;
   --background: #FAFAFA;

   /* BESPOKE */
   --shadow1: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   --shadow2: #E350562B 0px 8px 24px;

   /* spacing */
   --margin: 80px;
   --s0: 10px;
   --s1: 20px;
   --s2: 30px;
   --s3: 60px;
   --s4: 80px;
   --s5: 100px;
}

body {
   background-color: var(--background);
}

.bodyContainer {
   flex: 100% 1;
   /* background-image: url("./assets/Ornaments.svg"); */
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 0px 0px;
   display: flex;
   justify-content: center;
}

.bodyInner {
   width: 100%;
   padding: 80px;
   max-width: var(--max-content);
}

.invisible {
   visibility: hidden;
}

.gone {
   display: none !important;
}

.strikeOut {
   text-decoration: line-through;
}
.callToActionSuper {
   width: 100%;
   background-color: var(--black);
   display: flex;
   justify-content: center;
   color: white;
}

.callToAction {
   text-align: center;
   text-transform: uppercase;
   padding: 0.4em;
   font-family: 'Futura';
   font-weight: 500;
   width: 100%;
   max-width: var(--max-content);
   position: relative;
}


span.bold {
   font-weight: 700;
   text-decoration: underline;
}

div.dismiss {
   display: inline-block;
   position: absolute;
   right: 230px;
   top: 0;
   text-transform: lowercase;
   font-size: 1.4rem;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   cursor: pointer;
}

header {
   background-color: white;
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   box-shadow: #7a7a7a1a -4px 9px 25px -6px;
   z-index: 98;
}

.headerInner {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 0 80px;
   max-width: var(--max-content);
   justify-content: space-between;
}

nav {
   display: flex;
   justify-content: space-between;
   height: 88px;
   grid-gap: 1.5em;
   gap: 1.5em;
   padding-top: 15px;
   /* flex: 100%; */
}

nav aside .logo {
   position: relative;
   bottom: -14px;
   background-color: white;
   box-shadow: #0000001a 4px 9px 25px -6px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100px;
   width: 100px;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
}

nav .inputContainer {
   display: flex;
   align-items: center;
}

nav input {
   padding: 0.6em 1em;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
   border: 1px solid var(--gray);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

nav input:focus {
   outline: none;
}

nav input:-ms-input-placeholder {
   color: var(--gray)
}

nav input::placeholder {
   color: var(--gray)
}

nav aside {
   /* width: 200px; */
   display: flex;
   justify-content: center;
   align-items: center;
   grid-gap: 1.5em;
   gap: 1.5em;
}

/* nav aside:first-of-type {
   justify-content: flex-start;
   padding-left: 2em;
}

nav aside:last-of-type {
   justify-content: flex-end;
   padding-right: 2em;
} */

nav ul {
   display: flex;
   flex: 100% 1;
   align-items: center;
   justify-content: space-between;
   list-style: none;
   padding: 0;
   font-family: 'Futura';
}

nav ul a {
   text-decoration: none;
   color: inherit;
}

nav ul li {
   padding: 0.5em 1em;
   font-size: 18px;
   text-transform: uppercase;
   font-weight: 500;
   border: 1px solid transparent;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   cursor: pointer;
   transition: color 100ms ease-in-out;
   -webkit-transition: color 100ms ease-in-out;
   -moz-transition: color 100ms ease-in-out;
   -ms-transition: color 100ms ease-in-out;
   -o-transition: color 100ms ease-in-out;
}

nav ul li:hover {
   color: var(--red);
}
.blueDiv {
  width: 100%;
  background-color: var(--black);
  height: 390px;
  color: white;
}

footer {
  display: flex;
  justify-content: space-evenly;
}

.footerItems {
  text-align: justify; 
}

footer p {
  margin-top: 80px;
  font-size: 18px;
  margin-bottom: 10px;
}

footer ul {
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}

footer ul li:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: blue;
          text-decoration-color: blue;
}

.icon {
    margin-right: 15px;
    cursor: pointer;
}

footer input {
  background: #ffffff;
  border: 1px solid #b9b3b8;
  box-sizing: border-box;
  border-radius: 10px;
  height: 38px;
  width: 246px;
  color: #b9b3b8;
  font-size: 16px;
  line-height: 21px;
  text-align-last: center;
  cursor: pointer;
}

/* .mellowDiv {
  width: 100%;
  background-color: var(--mellow);
  height: 31.2px;
} */

.pageContainer {
   display: flex;
   flex-direction: column;
   grid-gap: var(--s5);
   gap: var(--s5);
   text-align: left;
}

a {
   color: var(--link);
}

section#pageHeader {
   display: flex;
   flex-direction: column;
   grid-gap: var(--s2);
   gap: var(--s2);
}

.pageTitle {
   padding: var(--s1) 160px;
   background-color: var(--light-grey);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   text-align: start;
   color: var(--primary);
   font-size: 30px;
   text-transform: uppercase;
}

.pageBanner {
   position: relative;
   height: 150px;
   background-size: cover;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.pageCrumb {
   color: var(--font-secondary);
   position: absolute;
   bottom: -28px;
   left: 100px;
   right: 100px;
   text-align: start;
   padding: var(--s1) var(--s2);
   padding-right: calc(var(--s3) * 3);
   background-color: var(--white);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   box-shadow: var(--shadow1);
}

.pageCrumb .crumbProduct {
   color: var(--black);
}


section#productContainer {
   padding: 0 var(--s5);
   display: flex;
   flex-direction: row;
   grid-gap: var(--s3);
   gap: var(--s3);
}

.productColumn {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   flex: 40% 1;
   grid-gap: var(--s1);
   gap: var(--s1);
}

.productColumn:first-of-type {
   flex: 60% 1;
}

.productColumn#productPictures {
   flex-direction: row;
}

#productDetails {
   display: flex;
   flex-direction: column;
   grid-gap: var(--s1);
   gap: var(--s1);
}

.productColumn h1 {
   font-size: 26px;
   font-weight: 500;
}

.productColumn h3 {
   font-size: 18px;
   font-weight: 500;
   color: var(--font-secondary)
}

.productCard {
   background-color: var(--white);
   box-shadow: var(--shadow1);
   flex: 75% 1;
   border-radius: 25px;
   -webkit-border-radius: 25px;
   -moz-border-radius: 25px;
   -ms-border-radius: 25px;
   -o-border-radius: 25px;
   padding: var(--s1)
}

.productCard img {
   height: 100%;
   width: 100%;
   object-fit: contain;
}

.productCard.primary {}

.productCard.secondary {}

.productColumn .triplet {
   display: flex;
   flex: 25% 1;
   flex-direction: column;
   grid-gap: var(--s1);
   gap: var(--s1);
}

#productReview {
   display: flex;
   flex-direction: row;
   grid-gap: var(--s1);
   gap: var(--s1)
}

.productStars {
   display: flex;
   grid-gap: 4px;
   gap: 4px;
}

.productStars>* {
   height: 18px;
}

#productPrice {
   font-size: 24px;
   display: flex;
   grid-gap: var(--s2);
   gap: var(--s2);
}

#productPrice #salePrice {
   color: var(--red);
   font-weight: 700;
}

#productOptions {
   display: flex;
   grid-gap: var(--s2);
   gap: var(--s2);
}

#productOptions label {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   grid-gap: 5px;
   gap: 5px;
}

.filterBorder {
   font-size: 18px;
   border: 1px solid var(--black);
   padding: var(--s0);
   border-radius: var(--s0);
   -webkit-border-radius: var(--s0);
   -moz-border-radius: var(--s0);
   -ms-border-radius: var(--s0);
   -o-border-radius: var(--s0);
}

#productWeight {
   width: 140px;
}

#quantityController {
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   display: flex;
   justify-content: space-between;
   align-items: center;
   grid-gap: var(--s0);
   gap: var(--s0)
}

.quantityStepper {
   font-family: sans-serif;
   transition: border-color 200ms ease-in-out;
   -webkit-transition: border-color 200ms ease-in-out;
   -moz-transition: border-color 200ms ease-in-out;
   -ms-transition: border-color 200ms ease-in-out;
   -o-transition: border-color 200ms ease-in-out;
   border: 1px solid transparent;
   border-radius: 3px;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   -ms-border-radius: 3px;
   -o-border-radius: 3px;
   cursor: pointer;
   padding: 0 10px;
}

.quantityStepper:hover {
   border-color: var(--gray);
}

.quantityStepper--inactive {
   color: var(--gray);
}

#shippingOptions {
   display: flex;
   grid-gap: var(--s2);
   gap: var(--s2);
}

#shippingOptions label {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   grid-gap: 5px;
   gap: 5px;
}

#shippingOptions select {
   font-size: 16px;
}

#shippingOptions a {
   font-style: italic;
}

#bag {
   display: flex;
   flex-direction: column;
   grid-gap: var(--s1);
   gap: var(--s1);
}

#bag .buttonContainer {
   display: flex;
   grid-gap: var(--s1);
   gap: var(--s1);
}


#bag .buttonContainer button {
   height: 44px;
   font-size: 18px;
   padding: 10px 25px;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   border: 0;
   cursor: pointer;
   transition: all 100ms ease-in-out;
   -webkit-transition: all 100ms ease-in-out;
   -moz-transition: all 100ms ease-in-out;
   -ms-transition: all 100ms ease-in-out;
   -o-transition: all 100ms ease-in-out;
   box-shadow: var(--shadow1);
}

#bag #addToBag {
   background-color: var(--primary);
   color: white;
}

#bag #heartButton {
   width: 44px;
   background-color: white;
   padding: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* #bag .buttonContainer button:hover {
   box-shadow: var(--shadow2);
   transform: scale(1.05);
   -webkit-transform: scale(1.05);
   -moz-transform: scale(1.05);
   -ms-transform: scale(1.05);
   -o-transform: scale(1.05);
} */

#afterPayContainer {
   height: 44px;
}

#afterPayContainer img {
   height: 100%;
   width: 100%;
   object-fit: contain;
}

.freeSampleContainer {
   display: flex;
   flex-direction: row;
   grid-gap: var(--s0);
   gap: var(--s0);
   align-items: center;
}

#freeSampleTagline {
   font-size: 14px;
   font-style: italic;
   color: var(--black)
}

#freeSampleButton {
   border: 0;
   color: var(--primary);
   border-radius: 500px;
   padding: 5px 10px;
   font-size: 11px;
   width: auto;
   height: 28px;
   background-color: var(--primary-light);
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   cursor: pointer;
}

section#productDescription {
   background-color: var(--white);
   overflow: hidden;
   box-shadow: var(--shadow1);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

#descriptionHeading {
   display: flex;
   grid-gap: var(--s2);
   gap: var(--s2);
   height: 68px;
   padding: 0 var(--s2);
   box-shadow: var(--shadow1);
}

#descriptionHeading div {
   height: 100%;
   font-size: 18px;
   text-transform: uppercase;
   display: flex;
   align-items: center;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   cursor: pointer;
   transition: color 100ms ease-in-out;
   -webkit-transition: color 100ms ease-in-out;
   -moz-transition: color 100ms ease-in-out;
   -ms-transition: color 100ms ease-in-out;
   -o-transition: color 100ms ease-in-out;
}

#descriptionHeading div:hover {
   color: var(--primary);
}

#descriptionContent {
   padding: var(--s2);
   display: flex;
   flex-direction: column;
   grid-gap: var(--s2);
   gap: var(--s2);
}

.activeTab {
   color: var(--primary)
}

.tagSuper {}

.tagContainer {
   display: flex;
   grid-gap: var(--s1);
   gap: var(--s1);
   padding-top: var(--s0);
}

.productTag {
   text-transform: capitalize;
   color: white;
   display: flex;
   background-color: red;
   padding: 5px 10px;
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
}

.productTag::before {
   content: "●";
}

section#reviews {
   display: flex;
   flex-direction: column;
   grid-gap: var(--s3);
   gap: var(--s3);
}

.reviewSort {
   display: flex;
   justify-content: flex-end;
}

.reviewSort .sortButton {
   background-color: var(--white);
   color: var(--primary);
   border: 0;
   padding: var(--s0);
   box-shadow: var(--shadow1);
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   cursor: pointer;
   transition: all 100ms ease-in-out;
   -webkit-transition: all 100ms ease-in-out;
   -moz-transition: all 100ms ease-in-out;
   -ms-transition: all 100ms ease-in-out;
   -o-transition: all 100ms ease-in-out;
}

.sortButton:hover {
   box-shadow: var(--shadow2);
   background-color: var(--primary);
   color: var(--white);
}


.reviewContainer {
   background-color: white;
   box-shadow: var(--shadow1);
   padding: var(--s2);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.reviewItem .ratingWrapper {
   padding: var(--s0) 0;
}

.reviewName {
   font-size: 18px;
}

.reviewDate {
   color: var(--dark-grey);
   font-size: 14px;
   margin-left: var(--s1);
}

.reviewContent {
   font-size: 14px;
   /* padding-top: var(--s0); */
}

.reviewFeedback {
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   align-items: center;
   margin: var(--s2) 0;
   grid-gap: var(--s2);
   gap: var(--s2);
}

.reviewFeedbackHelpful span {
   margin-right: var(--s0);
}

.reviewFeedback button {
   font-size: 11px;
   height: 28px;
   width: 55px;
   margin: 0 3px;
   padding: 5px;
   border-radius: 100px;
   border: 0;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
   box-shadow: var(--shadow1);
}
.reviewFeedback #yesButton {
   color: var(--white);
   background-color: var(--primary);
}

.reviewFeedback #noButton{
   color: var(--primary);
   background-color: var(--primary-light);
}

.reviewFeedbackRecommend {
   color: var(--dark-grey);
}
.mainDogBanner {
  background-image: "./dog.svg";
  box-shadow: #0000001a 4px 9px 25px -6px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.dogBanner2 {
  width: 1280px;
  height: 359px;
  margin-top: 30px;
}

.ourStore {
  margin-top: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  width: 210px;
  height: 32px;
  margin-bottom: 7px;
}

.searchLabel {
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  text-align-last: left;
  justify-content: space-evenly;
  background-color: white;
  padding: 0.1em 82px 1.8em;
  border-radius: 10px;
  box-shadow: #0000001a 4px 9px 25px -6px;
  width: 1062px;
  top: -80px;
  left: 100px;
}

.row {
  display: flex;
  flex-direction: row;
}

.selectBoxLarge {
  width: 267px;
  height: 44px;
  border: 1px solid #b9b3b8;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 50px;
}

label {
  font-size: 14px;
}

.search {
  width: 160px;
  height: 44px;
  margin-top: auto;
  background: #e35056;
  border-radius: 21px;
  border: none;
  text-align-last: center;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.search:-ms-input-placeholder {
  color: #ffffff;
}

.search::placeholder {
  color: #ffffff;
}

aside#goFetch {
  margin-left: auto;
  -webkit-margin-before: auto;
          margin-block-start: auto;
}

.show {
  padding-top: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 105px;
  margin-right: 105px;
}

.show label {
  font-size: 16px;
  margin-right: 10px;
}

.show select {
  width: 149px;
  font-size: 16px;
  height: 44px;
  border: 1px solid #39363c;
  box-sizing: border-box;
  border-radius: 10px;
}

.productsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: 90px;
  margin-right: 90px;
}

.productCard1 {
  width: 240px;
  height: 304px;
  background-color: var(--white);
  box-shadow: var(--shadow1);
  border-radius: 10px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productCard1 img {
  width: 193px;
  height: 206px;
  object-fit: contain;
}

.productCard1 p1 {
  font-size: 18px;
  line-height: 24px;
}

.productCard1 p2 {
  font-size: 16px;
  line-height: 21px;
  color: #b9b3b8;
}

.price {
  line-height: 24px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.productCard1 p3 {
  font-size: 18px;
  line-height: 24px;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  margin-right: 5px;
}
.productCard1 p4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 48, 56, 0.9);
}

.show {
  margin-top: -90px;
  margin-bottom: 80px;
}
.show p5 {
  font-size: 16px;
line-height: 21px;
color: #B9B3B8;
margin: auto;
cursor: pointer;
}
