.callToActionSuper {
   width: 100%;
   background-color: var(--black);
   display: flex;
   justify-content: center;
   color: white;
}

.callToAction {
   text-align: center;
   text-transform: uppercase;
   padding: 0.4em;
   font-family: 'Futura';
   font-weight: 500;
   width: 100%;
   max-width: var(--max-content);
   position: relative;
}


span.bold {
   font-weight: 700;
   text-decoration: underline;
}

div.dismiss {
   display: inline-block;
   position: absolute;
   right: 230px;
   top: 0;
   text-transform: lowercase;
   font-size: 1.4rem;
   user-select: none;
   cursor: pointer;
}

header {
   background-color: white;
   position: sticky;
   top: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   box-shadow: #7a7a7a1a -4px 9px 25px -6px;
   z-index: 98;
}

.headerInner {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 0 80px;
   max-width: var(--max-content);
   justify-content: space-between;
}

nav {
   display: flex;
   justify-content: space-between;
   height: 88px;
   gap: 1.5em;
   padding-top: 15px;
   /* flex: 100%; */
}

nav aside .logo {
   position: relative;
   bottom: -14px;
   background-color: white;
   box-shadow: #0000001a 4px 9px 25px -6px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100px;
   width: 100px;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
}

nav .inputContainer {
   display: flex;
   align-items: center;
}

nav input {
   padding: 0.6em 1em;
   height: fit-content;
   border: 1px solid var(--gray);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

nav input:focus {
   outline: none;
}

nav input::placeholder {
   color: var(--gray)
}

nav aside {
   /* width: 200px; */
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1.5em;
}

/* nav aside:first-of-type {
   justify-content: flex-start;
   padding-left: 2em;
}

nav aside:last-of-type {
   justify-content: flex-end;
   padding-right: 2em;
} */

nav ul {
   display: flex;
   flex: 100%;
   align-items: center;
   justify-content: space-between;
   list-style: none;
   padding: 0;
   font-family: 'Futura';
}

nav ul a {
   text-decoration: none;
   color: inherit;
}

nav ul li {
   padding: 0.5em 1em;
   font-size: 18px;
   text-transform: uppercase;
   font-weight: 500;
   border: 1px solid transparent;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   user-select: none;
   cursor: pointer;
   transition: color 100ms ease-in-out;
   -webkit-transition: color 100ms ease-in-out;
   -moz-transition: color 100ms ease-in-out;
   -ms-transition: color 100ms ease-in-out;
   -o-transition: color 100ms ease-in-out;
}

nav ul li:hover {
   color: var(--red);
}