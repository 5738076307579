.pageContainer {
   display: flex;
   flex-direction: column;
   gap: var(--s5);
   text-align: left;
}

a {
   color: var(--link);
}

section#pageHeader {
   display: flex;
   flex-direction: column;
   gap: var(--s2);
}

.pageTitle {
   padding: var(--s1) 160px;
   background-color: var(--light-grey);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   text-align: start;
   color: var(--primary);
   font-size: 30px;
   text-transform: uppercase;
}

.pageBanner {
   position: relative;
   height: 150px;
   background-size: cover;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.pageCrumb {
   color: var(--font-secondary);
   position: absolute;
   bottom: -28px;
   left: 100px;
   right: 100px;
   text-align: start;
   padding: var(--s1) var(--s2);
   padding-right: calc(var(--s3) * 3);
   background-color: var(--white);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   box-shadow: var(--shadow1);
}

.pageCrumb .crumbProduct {
   color: var(--black);
}


section#productContainer {
   padding: 0 var(--s5);
   display: flex;
   flex-direction: row;
   gap: var(--s3);
}

.productColumn {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   flex: 40%;
   gap: var(--s1);
}

.productColumn:first-of-type {
   flex: 60%;
}

.productColumn#productPictures {
   flex-direction: row;
}

#productDetails {
   display: flex;
   flex-direction: column;
   gap: var(--s1);
}

.productColumn h1 {
   font-size: 26px;
   font-weight: 500;
}

.productColumn h3 {
   font-size: 18px;
   font-weight: 500;
   color: var(--font-secondary)
}

.productCard {
   background-color: var(--white);
   box-shadow: var(--shadow1);
   flex: 75%;
   border-radius: 25px;
   -webkit-border-radius: 25px;
   -moz-border-radius: 25px;
   -ms-border-radius: 25px;
   -o-border-radius: 25px;
   padding: var(--s1)
}

.productCard img {
   height: 100%;
   width: 100%;
   object-fit: contain;
}

.productCard.primary {}

.productCard.secondary {}

.productColumn .triplet {
   display: flex;
   flex: 25%;
   flex-direction: column;
   gap: var(--s1);
}

#productReview {
   display: flex;
   flex-direction: row;
   gap: var(--s1)
}

.productStars {
   display: flex;
   gap: 4px;
}

.productStars>* {
   height: 18px;
}

#productPrice {
   font-size: 24px;
   display: flex;
   gap: var(--s2);
}

#productPrice #salePrice {
   color: var(--red);
   font-weight: 700;
}

#productOptions {
   display: flex;
   gap: var(--s2);
}

#productOptions label {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.filterBorder {
   font-size: 18px;
   border: 1px solid var(--black);
   padding: var(--s0);
   border-radius: var(--s0);
   -webkit-border-radius: var(--s0);
   -moz-border-radius: var(--s0);
   -ms-border-radius: var(--s0);
   -o-border-radius: var(--s0);
}

#productWeight {
   width: 140px;
}

#quantityController {
   user-select: none;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: var(--s0)
}

.quantityStepper {
   font-family: sans-serif;
   transition: border-color 200ms ease-in-out;
   -webkit-transition: border-color 200ms ease-in-out;
   -moz-transition: border-color 200ms ease-in-out;
   -ms-transition: border-color 200ms ease-in-out;
   -o-transition: border-color 200ms ease-in-out;
   border: 1px solid transparent;
   border-radius: 3px;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   -ms-border-radius: 3px;
   -o-border-radius: 3px;
   cursor: pointer;
   padding: 0 10px;
}

.quantityStepper:hover {
   border-color: var(--gray);
}

.quantityStepper--inactive {
   color: var(--gray);
}

#shippingOptions {
   display: flex;
   gap: var(--s2);
}

#shippingOptions label {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   gap: 5px;
}

#shippingOptions select {
   font-size: 16px;
}

#shippingOptions a {
   font-style: italic;
}

#bag {
   display: flex;
   flex-direction: column;
   gap: var(--s1);
}

#bag .buttonContainer {
   display: flex;
   gap: var(--s1);
}


#bag .buttonContainer button {
   height: 44px;
   font-size: 18px;
   padding: 10px 25px;
   border-radius: 500px;
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   border: 0;
   cursor: pointer;
   transition: all 100ms ease-in-out;
   -webkit-transition: all 100ms ease-in-out;
   -moz-transition: all 100ms ease-in-out;
   -ms-transition: all 100ms ease-in-out;
   -o-transition: all 100ms ease-in-out;
   box-shadow: var(--shadow1);
}

#bag #addToBag {
   background-color: var(--primary);
   color: white;
}

#bag #heartButton {
   width: 44px;
   background-color: white;
   padding: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* #bag .buttonContainer button:hover {
   box-shadow: var(--shadow2);
   transform: scale(1.05);
   -webkit-transform: scale(1.05);
   -moz-transform: scale(1.05);
   -ms-transform: scale(1.05);
   -o-transform: scale(1.05);
} */

#afterPayContainer {
   height: 44px;
}

#afterPayContainer img {
   height: 100%;
   width: 100%;
   object-fit: contain;
}

.freeSampleContainer {
   display: flex;
   flex-direction: row;
   gap: var(--s0);
   align-items: center;
}

#freeSampleTagline {
   font-size: 14px;
   font-style: italic;
   color: var(--black)
}

#freeSampleButton {
   border: 0;
   color: var(--primary);
   border-radius: 500px;
   padding: 5px 10px;
   font-size: 11px;
   width: auto;
   height: 28px;
   background-color: var(--primary-light);
   -webkit-border-radius: 500px;
   -moz-border-radius: 500px;
   -ms-border-radius: 500px;
   -o-border-radius: 500px;
   cursor: pointer;
}

section#productDescription {
   background-color: var(--white);
   overflow: hidden;
   box-shadow: var(--shadow1);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

#descriptionHeading {
   display: flex;
   gap: var(--s2);
   height: 68px;
   padding: 0 var(--s2);
   box-shadow: var(--shadow1);
}

#descriptionHeading div {
   height: 100%;
   font-size: 18px;
   text-transform: uppercase;
   display: flex;
   align-items: center;
   user-select: none;
   cursor: pointer;
   transition: color 100ms ease-in-out;
   -webkit-transition: color 100ms ease-in-out;
   -moz-transition: color 100ms ease-in-out;
   -ms-transition: color 100ms ease-in-out;
   -o-transition: color 100ms ease-in-out;
}

#descriptionHeading div:hover {
   color: var(--primary);
}

#descriptionContent {
   padding: var(--s2);
   display: flex;
   flex-direction: column;
   gap: var(--s2);
}

.activeTab {
   color: var(--primary)
}

.tagSuper {}

.tagContainer {
   display: flex;
   gap: var(--s1);
   padding-top: var(--s0);
}

.productTag {
   text-transform: capitalize;
   color: white;
   display: flex;
   background-color: red;
   padding: 5px 10px;
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
}

.productTag::before {
   content: "●";
}

section#reviews {
   display: flex;
   flex-direction: column;
   gap: var(--s3);
}

.reviewSort {
   display: flex;
   justify-content: flex-end;
}

.reviewSort .sortButton {
   background-color: var(--white);
   color: var(--primary);
   border: 0;
   padding: var(--s0);
   box-shadow: var(--shadow1);
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
   user-select: none;
   cursor: pointer;
   transition: all 100ms ease-in-out;
   -webkit-transition: all 100ms ease-in-out;
   -moz-transition: all 100ms ease-in-out;
   -ms-transition: all 100ms ease-in-out;
   -o-transition: all 100ms ease-in-out;
}

.sortButton:hover {
   box-shadow: var(--shadow2);
   background-color: var(--primary);
   color: var(--white);
}


.reviewContainer {
   background-color: white;
   box-shadow: var(--shadow1);
   padding: var(--s2);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.reviewItem .ratingWrapper {
   padding: var(--s0) 0;
}

.reviewName {
   font-size: 18px;
}

.reviewDate {
   color: var(--dark-grey);
   font-size: 14px;
   margin-left: var(--s1);
}

.reviewContent {
   font-size: 14px;
   /* padding-top: var(--s0); */
}

.reviewFeedback {
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   align-items: center;
   margin: var(--s2) 0;
   gap: var(--s2);
}

.reviewFeedbackHelpful span {
   margin-right: var(--s0);
}

.reviewFeedback button {
   font-size: 11px;
   height: 28px;
   width: 55px;
   margin: 0 3px;
   padding: 5px;
   border-radius: 100px;
   border: 0;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   -ms-border-radius: 100px;
   -o-border-radius: 100px;
   box-shadow: var(--shadow1);
}
.reviewFeedback #yesButton {
   color: var(--white);
   background-color: var(--primary);
}

.reviewFeedback #noButton{
   color: var(--primary);
   background-color: var(--primary-light);
}

.reviewFeedbackRecommend {
   color: var(--dark-grey);
}