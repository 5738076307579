.mainDogBanner {
  background-image: "./dog.svg";
  box-shadow: #0000001a 4px 9px 25px -6px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.dogBanner2 {
  width: 1280px;
  height: 359px;
  margin-top: 30px;
}

.ourStore {
  margin-top: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  width: 210px;
  height: 32px;
  margin-bottom: 7px;
}

.searchLabel {
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  text-align-last: left;
  justify-content: space-evenly;
  background-color: white;
  padding: 0.1em 82px 1.8em;
  border-radius: 10px;
  box-shadow: #0000001a 4px 9px 25px -6px;
  width: 1062px;
  top: -80px;
  left: 100px;
}

.row {
  display: flex;
  flex-direction: row;
}

.selectBoxLarge {
  width: 267px;
  height: 44px;
  border: 1px solid #b9b3b8;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 50px;
}

label {
  font-size: 14px;
}

.search {
  width: 160px;
  height: 44px;
  margin-top: auto;
  background: #e35056;
  border-radius: 21px;
  border: none;
  text-align-last: center;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.search::placeholder {
  color: #ffffff;
}

aside#goFetch {
  margin-left: auto;
  margin-block-start: auto;
}

.show {
  padding-top: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 105px;
  margin-right: 105px;
}

.show label {
  font-size: 16px;
  margin-right: 10px;
}

.show select {
  width: 149px;
  font-size: 16px;
  height: 44px;
  border: 1px solid #39363c;
  box-sizing: border-box;
  border-radius: 10px;
}

.productsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
  margin-left: 90px;
  margin-right: 90px;
}

.productCard1 {
  width: 240px;
  height: 304px;
  background-color: var(--white);
  box-shadow: var(--shadow1);
  border-radius: 10px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productCard1 img {
  width: 193px;
  height: 206px;
  object-fit: contain;
}

.productCard1 p1 {
  font-size: 18px;
  line-height: 24px;
}

.productCard1 p2 {
  font-size: 16px;
  line-height: 21px;
  color: #b9b3b8;
}

.price {
  line-height: 24px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.productCard1 p3 {
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: line-through;
  margin-right: 5px;
}
.productCard1 p4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 48, 56, 0.9);
}

.show {
  margin-top: -90px;
  margin-bottom: 80px;
}
.show p5 {
  font-size: 16px;
line-height: 21px;
color: #B9B3B8;
margin: auto;
cursor: pointer;
}