@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
   text-align: center;
   display: flex;
   flex-direction: column;
   min-height: 100vh;

}

* {
   box-sizing: border-box;
   font-family: 'Jost', "Futura", sans-serif;
   /* COLORS */
   --red: #FF3038;
   --gray: #b9b3b8;
   --alt-gray: #eeeeee;
   --black: #39363c;
   --blue: #016592;
   --mellow: #ffc74e;
   --orange: #feac29;
   /* SIZES */
   --max-content: 1440px;

   /* COLORS v2 */
   --primary: #E35056;
   --primary-light: #E350562B;
   --dark-grey: #B9B3B8;
   --light-grey: #EEEEEE;
   --font-primary: #39363C;
   --font-secondary: var(--dark-grey);
   --link: #788AAE;
   --white: white;
   --background: #FAFAFA;

   /* BESPOKE */
   --shadow1: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   --shadow2: #E350562B 0px 8px 24px;

   /* spacing */
   --margin: 80px;
   --s0: 10px;
   --s1: 20px;
   --s2: 30px;
   --s3: 60px;
   --s4: 80px;
   --s5: 100px;
}

body {
   background-color: var(--background);
}

.bodyContainer {
   flex: 100%;
   /* background-image: url("./assets/Ornaments.svg"); */
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 0px 0px;
   display: flex;
   justify-content: center;
}

.bodyInner {
   width: 100%;
   padding: 80px;
   max-width: var(--max-content);
}

.invisible {
   visibility: hidden;
}

.gone {
   display: none !important;
}

.strikeOut {
   text-decoration: line-through;
}